<template>
	<div class="privacy">
		<!-- 头部 -->
		<logged-heard></logged-heard>
		<!-- 搜索 -->
		<div class="search">
			<search :jobCity="false" :particular="false" :posiName="posiName" @hotClick="hotClick"
				@searchClick="searchClick" @handleChange="handleChange"></search>
		</div>
		<div style="padding: 0 190px;" class="neirong">
			<div class="left-neirong">
				<div class="el-Job-listings" v-for="item in posiList" :key="item.id">
					<div class="el-top" @click="navTo('/Position-details', item.id)">
						<div class="list">
							<span>{{ item.position_name }}【{{ item.province }}】</span>
							<div class="askfor">
								<span v-if="item.salary_min != '面议'">{{ item.salary_min }}-{{ item.salary_max }}</span>
								<span v-else>{{ item.salary_min }}</span>
								&nbsp;
								<span>{{ item.experience }}</span> &nbsp;<span>{{ item.education }}</span>
							</div>
						</div>
						<div class="right-message">
							<div class="company pointer">
								<span>{{ item.ent.ent_name }}</span>
								<span>{{ item.ent.other_info.nature.text }}:{{item.ent.other_info.scale.text}}</span>
							</div>
							<div class="right-img">
								<img :src="item.ent.logo_url" alt="" />
							</div>
						</div>
					</div>
					<div class="social-benefits">
						<div class="left pointer">
							<span v-for="(itemOne, index) in item.ent.welfare.slice(0, 10)" :key="itemOne">
								<span>{{ itemOne }}</span> <span v-if="index == 9">...</span></span>
						</div>
						<div class="right pointer" v-show="userDetail.enterprise_id !== item.enterprise_id" @click="
                sendClick(
                  item.hr.user_id,
                  item.id,
                  item.ent.ent_name,
                  item.enterprise_id,
                  item.ent.logo_url,
                  item.hr.user_name,
                  item.position_name,
                  item.hr.id
                )
              ">
							<span>HR·{{ item.hr.user_name }}｜{{ item.hr.online_status }}</span>
						</div>
					</div>
				</div>
				<!-- 添加求职意向 -->
				<div class="add-intention" v-if="resumeObj && resumeObj.job_intention.length == 0">
					<span>添加求职意向，推荐专属职位</span>
					<div class="btn pointer" @click="navTo('my-resume/objective')">立即添加</div>
				</div>
				<!-- 分页 -->
				<div class="pagination">
					<el-pagination background layout="prev, pager, next" :total="total"
						:hide-on-single-page="valuePageShow" @current-change="currentChange">
					</el-pagination>
				</div>
			</div>
			<div class="right-message-one">
				<div class="el-right-login">
					<div class="name-message pointer" v-if="resumeObj">
						<img src="@/assets/login/922028.png" class="el-img" alt="" v-if="!resumeObj.avatar_url" />
						<img :src="resumeObj.avatar_url" class="el-img" alt="" v-else />
						<div>
							<div style="padding-left:60px;" class="title-one">
								<span>{{ resumeObj.name ? resumeObj.name : "未知" }}</span>
								<span class="pointer" @click="navTo('/my-resume')">编辑</span>
							</div>
							<div style="padding-left:65px;" class="education" v-if="resumeObj">
								<span>{{ resumeObj.other_info.age ? resumeObj.other_info.age : 0 }}岁 ｜
									{{
                    resumeObj.other_info.work_year ? resumeObj.other_info.work_year : 0
                  }}年 ｜
									{{
                    resumeObj.school.length > 0 ? resumeObj.school[0].education : "无"
                  }}</span>
							</div>
						</div>
					</div>
					<div class="el-now">
						<el-select v-model="queryOne.job_status" placeholder="请选择" @change="subChnage">
							<el-option v-for="item in jobList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="el-mesage" v-if="dataNum">
						<div class="meage-one pointer" @click="navTo('/jobHunting', 4)">
							<span>{{ dataNum.link_num }}</span>
							<span>在线沟通中</span>
						</div>
						<div class="meage-one pointer" @click="navTo('/jobHunting', 1)">
							<span>{{ dataNum.delivery_num }}</span>
							<span>已投递</span>
						</div>
						<div class="meage-one pointer" @click="navTo('/jobHunting', 2)">
							<span>{{ dataNum.interview_num }}</span>
							<span>面试</span>
						</div>
						<div class="meage-one pointer" @click="navTo('/jobHunting', 3)">
							<span>{{ dataNum.collection_num }}</span>
							<span>收藏</span>
						</div>
					</div>
				</div>
				<div style="width: 100%;" class="el-accessory">
					<div class="el-title" style="display: flex;justify-content: space-between;">
						<span>附件管理</span>
						<i class="el-icon-close pointer" @click="delCv"></i>
					</div>
					
					<div v-if="annexStatus == '上传简历'" style="display: flex;justify-content: space-around;" class="el-resume-btn pointer"
						@click="cvShow">
						<span style="width: 70%;">{{annexStatus}}</span>
						<span>{{annexJudge}}</span>
					</div>
					<div v-else style="display: flex;justify-content: space-around;background: #fff;color: #333;" class="el-resume-btn">
						<span style="width: 70%;">{{annexStatus}}</span>
						<span>{{annexJudge}}</span>
					</div>
					<div class="el-operation-name">
						<span>在线简历</span>
						<span class="pointer" @click="navTo('/my-resume')">编辑</span>
					</div>
				</div>
				<div style="width: 100%;" class="el-bottom-code" v-if="kfObj">
					<div>专属人才客服</div>
					<div class="el-code">
						<img src="../../assets/forget/2.jpg" class="el-img" alt="" />
						<span class="el-span">很高兴为您服务，添加专属人才客服，求职有保障！</span>
					</div>
				</div>
			</div>
		</div>
		<upload-resume ref="cvUpload" @uploadCv="uploadCv"></upload-resume>
		<appendix-cv ref="appendCv" @cvUploadClick="cvUploadClick"></appendix-cv>
	</div>
</template>

<script>
	import loggedHeard from "../../components/loggedHeard.vue";
	import tabbarModel from "../../components/tabbar.vue";
	import uploadResume from "../../components/uploadResume.vue";
	import appendixCv from "../../components/appendixCv.vue";
	import search from "../../components/search.vue";
	import apiTool from "../../api/tool";
	import api from "../../api/user";
	import posiApi from "../../api/position";
	import {
		mapGetters,
		mapState
	} from "vuex";
	import TIM from "tim-js-sdk";
	import apiDuck from "../../api/user"; //hyz
	export default {
		components: {
			loggedHeard,
			tabbarModel,
			search,
			uploadResume,
			appendixCv
		},
		computed: {
			...mapGetters(["hasLogin"]),
			...mapState(["userDetail"]),
		},
		data() {
			return {
				queryOne: {
					name: "", //姓名
					job_status: "", //当前求职状态
					gender: 1, //性别 1男2女
					identity: "", //人才身份
					birthday: "", //生日
					work_time: "", //工作日期
					phone: "", //手机号码，
					province_id: "", //省id
					province: "", //省，
					city_id: "", //市id
					city: "", //市
				},
				jobList: [],
				query: {
					is_center: 1,
					position_id: "",
					page: 1,
					position_name: "",
				},
				posiList: [],
				total: 1,
				valuePageShow: false,
				resumeObj: null,
				kfObj: null,
				dataNum: null,
				posiName: "搜索职类",
				annexStatus: '上传简历',
				annexJudge: ''
			};
		},

		created() {
			apiDuck.userDetail().then((res) => {
				if (res.code == 200) {
					this.$store.commit("SET_USER", res.data);
					if (res.data.user_type == 2) {
						this.$emit("toggleClick");
						return;
					}
				}
			});
			
			this.toolist();
			this.getResume();
			this.posiClick();
			this.getPosiNum();
			this.kfObj = JSON.parse(localStorage.getItem("kfObj"));
		},

		mounted() {},

		methods: {
			cvUploadClick(){
				// 子组件调用父组件
				this.getResume()
			},
			delCv() {
				this.$confirm("此操作将永久删除该简历, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(() => {
						apiTool.delAnnexResume().then(res => {
							if (res.code == 200) {
								this.$util.msg('删除成功')
								this.getResume()
							}
						})
					})
			},
			handleChange(id) {
				this.query.position_id = id;
				this.query.page = 1;
				this.posiClick();
			},
			getPosiNum() {
				api.getPosiNum().then((res) => {
					if (res.code == 200) {
						this.dataNum = res.data;
					}
				});
			},
			//完成
			subChnage() {
				api.setResume(this.queryOne).then((res) => {
					if (res.code == 200) {
						this.$util.msg("编辑成功");
						this.getResume();
					}
				});
			},
			sendClick(id, posiId, entName, ent_id, ent_logo, contacts, jop, hr_id) {
				if (!this.hasLogin) {
					this.$util.msg("请先登录", "warning");
					return;
				}
				if (!this.userDetail.detail || this.userDetail.detail.length == 0) {
					this.$util.msg("请先完善简历", "warning");
					return;
				} else {
					if (this.userDetail.detail.completeness < 90) {
						this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
						return;
					}
				}
				if (this.userDetail.id == hr_id) return;
				if (this.userDetail.user_type !== 1) {
					this.$emit('toggleClick')
					return;
				}
				let query = {
					user_id: this.userDetail.id, //用户id
					user: this.userDetail.name, //用户名称
					head: this.userDetail.avatar_url, //用户头像
					ent_name: entName, //企业名称
					ent_id, //企业id
					ent_logo, //企业logo
					contacts, //企业hr名字
					jopId: posiId, //工作id
					jop, //职位名称
					customStatus: 1, //沟通状态
					id: hr_id, //hrid
				};
				let message = this.$tim.createCustomMessage({
					to: id.toString(),
					conversationType: TIM.TYPES.CONV_C2C,
					payload: {
						data: "text",
						description: "打扰了,希望和你聊聊这个职位，是否方便呢",
						extension: JSON.stringify(query),
					},
				});
				// 2. 发送消息
				let promise = this.$tim.sendMessage(message);
				promise
					.then((imResponse) => {
						this.$router.push({
							path: "/interaction",
							query: {
								id,
								posiId
							}
						});
					})
					.catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},
			currentChange(val) {
				this.query.page = val;
				this.posiClick();
			},
			searchClick(text) {
				this.query.position_name = text;
				this.query.page = 1;
				this.posiClick();
			},
			hotClick(id, position) {
				this.query.position_id = id;
				this.query.page = 1;
				this.posiName = position;
				this.posiClick();
			},
			// 获取职位
			posiClick() {
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				posiApi.posiList(this.query).then((res) => {
					if (res.code == 200) {
						this.posiList = res.data.data;
						this.total = res.data.total;
						if (res.data.last_psge == 1) {
							this.valuePageShow = false;
						} else {
							this.valuePageShow = true;
						}
					}
					loading.close()
				});
			},
			// 获取配置
			toolist() {
				apiTool.getUserConfig().then((res) => {
					if (res.code == 200) {
						this.jobList = res.data.job_status.options;
					}
				});
			},
			// 获取用户的简历
			getResume() {
				api.getResume().then((res) => {
					// console.log('getResume=====================',res)
					if (res.code == 200) {
						this.resumeObj = res.data;
						if (!res.data.detail) return;
						let detail = res.data.detail;
						this.queryOne.name = detail.name;
						this.queryOne.job_status = detail.job_status;
						this.queryOne.gender = detail.gender;
						this.queryOne.identity = detail.identity;
						this.queryOne.birthday = detail.birthday;
						this.queryOne.work_time = detail.work_time;
						this.queryOne.province_id = detail.province_id;
						this.queryOne.province = detail.province;
						this.queryOne.city_id = detail.city_id;
						this.queryOne.city = detail.city;
						switch (parseInt(detail.annex_status)) {
							case 0:
								this.annexStatus = '上传简历';
								this.annexJudge = '';
								break;
							case 1:
								this.annexStatus = !!detail.file_way ? detail.file_way : detail.annex_name;
								this.annexJudge = '待审核';
								break;
							case 2:
								this.annexStatus = !!detail.file_way ? detail.file_way : detail.annex_name;
								this.annexJudge = '审核通过';
								break;
							case 3:
								this.annexStatus = !!detail.file_way ? detail.file_way : detail.annex_name;
								this.annexJudge = '审核失败';
								break;
						}
					}
				});
			},
			navTo(path, id) {
				if (id) {
					this.$router.push({
						path,
						query: {
							id
						}
					});
					return;
				}
				this.$router.push({
					path
				});
			},
			cvShow() {
				this.$refs.cvUpload.show();
			},
			uploadCv() {
				this.$refs.appendCv.show();
			},
		},
	};
</script>
<style lang="less" scoped>
	.privacy {
		width: 100%;
		background: #f6f6f8;
		min-height: 100vh;

		.search {
			width: 58.125rem;
			margin: 0 auto;
			margin-bottom: 1.875rem;
		}

		.neirong {
			display: flex;
			width: 100%;

			.left-neirong {
				width: 65%;
				// margin-left: 9.8%;
				display: flex;
				flex-direction: column;
				position: relative;

				.el-Job-listings {
					width: 100%;
					float: left;
					height: 141px;
					background-color: #fff;
					margin-bottom: 10px;

					.el-top {
						display: flex;
						height: 96px;
						margin-bottom: 13px;
						border-bottom: 1px solid #eeeeee;
					}

					.list {
						display: flex;
						flex-direction: column;
						padding: 1.625rem 1.875rem;
						line-height: 1.5rem;
						width: 41rem;

						span {
							font-weight: bold;
							color: #126bf9;
							cursor: pointer;
						}

						.askfor {
							font-size: 0.9375rem;

							font-weight: 400;
							color: #61687c;

							span:nth-child(1) {
								font-size: 1rem;

								font-weight: 400;
								color: #fd7240;
							}

							span:nth-child(2) {
								font-size: 0.9375rem;

								font-weight: 400;
								color: #61687c;
							}

							span:nth-child(3) {
								font-size: 0.9375rem;

								font-weight: 400;
								color: #61687c;
							}
						}
					}

					.right-message {
						display: flex;
						padding-left: 4.25rem;

						.company {
							width: 27rem;
							display: flex;
							flex-direction: column;
							font-size: 1rem;
							overflow: hidden;
							font-weight: bold;
							line-height: 1.5625rem;
							color: #126bf9;
							padding: 1.25rem 1.25rem;

							span:nth-child(2) {
								font-size: 0.875rem;
								font-weight: 400;
								color: #61687c;
							}
						}

						.right-img {
							font-size: 0.875rem;
							font-weight: 400;
							color: #61687c;

							img {
								width: 3.5rem;
								height: 3.5rem;
								margin-top: 1.25rem;
							}
						}
					}
				}

				.add-intention {
					text-align: center;
					padding-top: 16%;
					font-size: 1rem;
					font-weight: 400;
					color: #8d92a1;

					.btn {
						width: 12.75rem;
						height: 2.625rem;
						line-height: 2.625rem;
						margin: 1.25rem auto;
						color: #fff;
						background: #126bf9;
					}
				}

				.pagination {
					margin: 0 auto;
					margin-bottom: 10px;
				}
			}

			.social-benefits {
				margin-left: 12px;

				.left {
					float: left;
					font-size: 0.875rem;

					font-weight: 400;
					color: #61687c;

					span {
						padding: 0.875rem;
					}
				}

				.right {
					padding: 0 0.9375rem;
					height: 1.4375rem;
					margin-right: 1.25rem;
					border: 0.0625rem solid #f3f3f3;
					border-radius: 12px;
					text-align: center;
					float: right;
					font-size: 0.9375rem;

					font-weight: 400;
					color: #6c7388;
				}
			}

			.right-message-one {
				display: flex;
				flex-direction: column;
				width: calc(35% - 20px);

				// width: 34%;
				.el-right-login {
					// width: 284px;
					width: 100%;
					height: 193px;
					margin-left: 20px;
					margin-bottom: 20px;
					background: #fff;

					.name-message {
						padding: 1.25rem 1rem;
						font-size: 0.875rem;
						line-height: 24px;
						font-weight: 400;
						color: #414a60;

						.el-img {
							width: 46px;
							height: 46px;
							float: left;
							border-radius: 50%;
						}

						.title-one {
							span:nth-child(1) {
								padding: 0 0.625rem;
							}

							span:nth-child(2) {
								float: right;
							}
						}

						.bianji {
							text-align: right;
						}

						.education {
							padding: 0 0.625rem;

							span {
								font-size: 0.8125rem;
							}
						}
					}

					.el-now {
						width: 248px;
						margin: 0 auto;
						margin-bottom: 22px;

						::v-deep .el-select .el-input__inner {
							margin: 0 auto;
							width: 256px;
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
						}

						::v-deep .el-select .el-input__inner:focus {
							border-color: #e3e7ed;
						}
					}

					.el-mesage {
						height: 50px;
						margin: auto;
						display: flex;
						padding: 0 0.625rem;
						font-size: 0.8125rem;
						font-weight: 400;
						flex-direction: row;

						color: #8d92a1;

						.meage-one {
							text-align: center;
							display: flex;
							flex: 1;
							flex-direction: column;

							span:nth-child(1) {
								font-size: 0.9375rem;
								font-weight: 400;
								color: #414a60;
							}
						}
					}
				}

				.el-accessory {
					width: 284px;
					height: 152px;
					margin: 0 0 20px 20px;
					padding: 20px 20px;
					background: #fff;
					font-size: 0.875rem;
					font-weight: 400;
					color: #414a60;

					.el-title {
						margin-bottom: 20px;
					}

					.el-resume-btn {
						text-align: center;
						margin: 0 auto;
						color: #fff;
						background: #126bf9;
						// width: 245px;
						width: 100%;
						height: 34px;
						line-height: 34px;
						margin-bottom: 20px;
					}

					.el-operation-name {
						span:nth-child(2) {
							color: #8d92a1;
							padding: 0 10px;
						}
					}
				}

				.el-bottom-code {
					background: #ffffff;
					background: #fff;
					font-size: 0.875rem;
					font-weight: 400;
					color: #414a60;
					width: 284px;
					height: 178px;
					margin-left: 20px;
					padding: 20px 20px;

					.el-code {
						display: flex;
						padding-top: 20px;

						.el-img {
							width: 91px;
							height: 91px;
							border-radius: 6px;
						}

						.el-span {
							font-size: 0.875rem;
							font-weight: 400;
							line-height: 26px;
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
</style>
